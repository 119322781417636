import React, { useState, useEffect } from "react";
// import ReactGA from "react-ga";
import { ThemeProvider } from "styled-components";
import { themes } from "./theme";
import { GlobalStyles } from "./global";
// import { settings } from "./portfolio";
import "./App.css";
import Main from "./containers/Main";

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  // useEffect(() => {
  //   if (settings.googleTrackingID) {
  //     ReactGA.initialize(settings.googleTrackingID, {
  //       testMode: process.env.NODE_ENV === "test",
  //     });
  //     ReactGA.pageview(window.location.pathname + window.location.search);
  //   }
  // }, []);

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyles />
      <div>
        <Main theme={themes[theme]} setTheme={setTheme} />
      </div>
    </ThemeProvider>
  );
}

export default App;
